// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-audience-analytics-js": () => import("./../../../src/pages/audience-analytics.js" /* webpackChunkName: "component---src-pages-audience-analytics-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-bet-king-js": () => import("./../../../src/pages/projects/bet-king.js" /* webpackChunkName: "component---src-pages-projects-bet-king-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-la-casera-js": () => import("./../../../src/pages/projects/la-casera.js" /* webpackChunkName: "component---src-pages-projects-la-casera-js" */),
  "component---src-pages-projects-maltina-js": () => import("./../../../src/pages/projects/maltina.js" /* webpackChunkName: "component---src-pages-projects-maltina-js" */),
  "component---src-pages-projects-samsung-js": () => import("./../../../src/pages/projects/samsung.js" /* webpackChunkName: "component---src-pages-projects-samsung-js" */),
  "component---src-pages-projects-star-js": () => import("./../../../src/pages/projects/star.js" /* webpackChunkName: "component---src-pages-projects-star-js" */)
}

